.search-container {
    width: 100%;
    min-height: calc(100vh - 7.2rem);
    display: none;
    position: fixed;
    top: 7.2rem;
    left: 0;
    background: rgba(0, 0, 0, 0.8);
    z-index: 5;
}

.search-container .background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.search-container .content {
    z-index: 2;
    position: relative;
}

#site-search {
    width: 100%;
    background: transparent;
    text-transform: uppercase;
    font-size: 4rem;
    font-family: Oswald, sans-serif;
    padding: 1rem 0;
    color: white;
    border: 0;
}

#site-search:focus {
    border: 0;
    box-shadow: none;
}

.search-input {
    border-bottom: 1px solid white;
}

.main-content {
    transition: all 0.2s linear;
}

.main-content.blured {
    filter: blur(5px);
    overflow: hidden;
}

footer.blured {
    filter: blur(5px);
}

body.noscroll {
    overflow: hidden;
}

header.blured {
    background-color: rgba(0, 0, 0, 1);
}

#search-loading {
    position: absolute;
    display: none;
    left: 50%;
    margin-top: 3rem;
}

.search-results {
    overflow-y: auto;
    height: 100%;
    overflow-x: hidden;
    max-height: calc(100vh - 23rem);
}

.search-results ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.search-results ul a {
    font-family: 'Oswald', sans-serif;
    text-transform: uppercase;
}

.search-results ul a,
.search-results h3 {
    color: white;
}

.search-results ul a:hover {
    color: #fadb32;
}

.search-results h3 {
    margin-bottom: 1rem;
    padding-bottom: 0.5rem;
    font-size: 3rem;
    border-bottom: 1px solid white;
}

.search-no-response p {
    color: white;
    font-family: 'Oswald', sans-serif;
    font-size: 2rem;
}

.close-search {
    position: absolute;
    top: 2rem;
    right: 2rem;
    cursor: pointer;
    color: white;
}