.user-container {
    margin-top: 8rem;
}

.user-container.user-page {
    margin-top: 0;
}

.user-container input[type=password],
.user-container input[type=text],
.user-container input[type=email] {
    min-width: 25rem;
}

.user-container hr {
    max-width: 20rem;
    background: #0e1c2a;
    margin: 1rem 0 1rem 1.5rem;
}

.user-container .activity hr {
    margin: 1rem 0;
}

.user-container h3,
.user-container p {
    margin: 0;
}

.user-container a:hover,
.user-container a:focus {
    color: #0e1c2a;
}

.user-map {
    display: none;
}

#userMap {
    width: 100%;
    height: 35rem;
}

#upload_cover {
    visibility: hidden;
    background-color: rgba(0, 0, 0, 0.2);
}

.profile-header:hover #upload_cover {
    visibility: visible;
}

.mask {
    background-image: linear-gradient(to bottom, transparent 0, rgba(0, 0, 0, .75) 85%);
}

.profile-header .mask h1,
.user-titles h2 {
    color: white;
}

.user-titles h2 {
    font-size: 1.6rem;
}

.user-titles h2 span {
    color: #fadb32;
}