@font-face {
    font-family: 'Museo';
    src: url('/fonts/Museo.otf');
}

* {
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body {
    font-family: "Raleway", Arial, sans-serif;
}

button {
    cursor: pointer;
    outline: none;
    font-family: 'Oswald', sans-serif;
    text-transform: uppercase;
}

h1, h2, h3, h4 {
    font-family: "Museo", Arial, sans-serif;
    line-height: normal;
}

h2 {
    font-size: 2rem;
}

h3 {
    font-size: 1.8rem;
}

h4 {
    font-size: 1.6rem;
}

a {
    text-decoration: none;
    transition: all 0.1s ease-in-out;
    color: #0e1c2a;
}

a.btn {
    padding: 0.5rem 3rem;
    background-color: #fff6d1;
    border-radius: 2px;
}

a.active {
    font-weight: 700;
}

label {
    margin: 0;
}

p.small {
    font-size: 1.2rem;
}

hr {
    max-width: 20rem;
    background: #0e1c2a;
    margin: 1rem 0;
}

.b-bot {
    border-bottom: 1px solid #f5f5f5;
}

.hidden {
    display: none;
}

.ptxl {
    padding-top: 5rem;
}

.full-vh {
    min-height: 100vh;
}

footer a:hover,
footer a:focus,
footer a.btn:hover,
header a:hover,
header a:focus,
header a.btn:hover {
    color: #fadb32;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
    color: rgba(255, 246, 209, 0.9);
    background: rgba(14, 28, 42, 1);;
}

.select2-container--default .select2-search--dropdown .select2-search__field {
    border: 1px solid rgba(14, 28, 42, 1);;
}

header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: rgba(0, 0, 0, 0.6);
    box-shadow: 0 1px 6px rgba(0, 0, 0, .2);
    color: white;
    z-index: 110;
    padding: 1rem 0;
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    -ms-transition: all 0.2s;
    -o-transition: all 0.2s;
    transition: all 0.2s;
}

header.active {
    background: rgba(0, 0, 0, 0.9);
}

header a, footer a {
    color: white;
    font-family: 'Oswald', sans-serif;
    text-transform: uppercase;
}

header ul li.logo {
    margin-left: 1rem;
    font-size: 1.8rem;
}

header ul li.logo a {
    position: relative;
    z-index: 1;
}

header .logo span {
    font-size: 1rem;
    text-transform: lowercase;
    position: absolute;
    color: rgb(255, 246, 209);
    top: 1.3rem;
    right: 0;
}

main {
    position: relative;
    min-height: 100vh;
}

.btn-yellow {
    border: 1px solid #fadb32;
    color: #fadb32;
    border-radius: 4px;
    padding: 0.5rem;
}

.btn-yellow:hover {
    color: white;
}

header ul li {
    position: relative;
    display: inline-block;
    padding-left: 1rem;
    float: left;
}

header ul li.divider {
    color: #fadb32;
}

header ul {
    list-style: none;
    line-height: 5rem;
    padding: 0;
}

.message_popin {
    display: none;
    position: fixed;
    background-color: rgba(255, 255, 255, 1);
    border: 1px solid rgba(14, 28, 42, 1);
    z-index: 1000;
    height: auto;
    padding: 2rem;
    width: auto;
    max-width: 50rem;
    text-align: center;
    top: 30%;
    left: 0;
    right: 0;
    margin: auto;
    color: rgba(14, 28, 42, 1);
    border-radius: 5px;
}

.btn-alt {
    border: 0;
    background: transparent;
    color: white;
    padding: 0;
    transition: all .1s ease-in-out;
}

.btn-alt:hover {
    color: rgba(255, 246, 209, 0.9);
}

#beers-button, button {
    cursor: pointer;
}

#beers-button i {
    float: right;
    margin-right: 1rem;
}

#close {
    position: absolute;
    top: 1rem;
    right: 1rem;
    padding: 2px;
    cursor: pointer;
}

.caption {
    color: white;
    text-align: left;
}

.caption h1 {
    font-size: 4rem;
    font-family: 'Museo', "Raleway", Arial, sans-serif;
    margin: 0;
}

.caption h2 {
    font-size: 3rem;
    font-family: 'Raleway', sans-serif;
    margin: 1rem 0;
}

.index-search {
    height: 66vh;
    position: relative;
    z-index: 2;
}

#search {
    width: 100%;
    vertical-align: middle;
    padding: 1rem;
    margin: auto;
    background: white;
    color: #0e1c2a;
    position: relative;
    border-radius: 4px;
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, .2);
}

#search button[type=submit] {
    position: absolute;
    height: 6.1rem;
    top: 0;
    right: 0;
    border-radius: 0;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    padding: 1rem 2rem;
    color: white;
    background: #0e1c2a;
    border: 0;
    font-family: 'Oswald', sans-serif;
}

#search button[type=submit]:hover {
    background: rgba(14, 28, 42, 0.8);
}

#loading {
    display: none;
    position: fixed;
    margin: auto;
    width: 100%;
    max-width: 100vw;
    height: 100vh;
    z-index: 399;
    background: rgba(255, 255, 255, 0.2);
}

#reset {
    cursor: pointer;
}

#search .divider {
    position: relative;
    top: 0.8rem;
    opacity: 0.3;
}

/****** main page ******/
#map {
    position: absolute !important;
    right: 0;
    top: 7rem;
    z-index: 2;
    width: 40%;
    height: calc(100vh - 7rem);
    opacity: 1;
    transition: 0.2s all ease-in-out;
}

.sidebar {
    width: 60%;
    position: absolute;
    top: 7rem;
    left: 1rem;
    bottom: 5rem;
    overflow-y: auto;
    height: calc(100vh - 7rem);
    align-content: flex-start;
}

.sidebar a {
    color: rgba(14, 28, 42, 1);
}

.sidebar a:hover {
    color: rgba(14, 28, 42, 0.8);
}

.bar-skills img {
    height: 100%;
    max-height: 6rem;
    max-width: 6rem;
}

#searchtop {
    text-align: center;
    vertical-align: middle;
    position: fixed;
    top: -2rem;
    left: 0;
    max-width: 100vw;
    z-index: 11;
    margin: auto;
    color: rgba(14, 28, 42, 1);
    background-color: white;
    box-shadow: 0 1px 3px rgba(0, 0, 0, .2);
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    -ms-transition: all 0.2s;
    -o-transition: all 0.2s;
    transition: all 0.2s;
}

#searchtop.displayed {
    -webkit-transform: translateY(7rem);
    -moz-transform: translateY(7rem);
    -ms-transform: translateY(7rem);
    -o-transform: translateY(7rem);
    transform: translateY(7rem);
}

#searchtop input[type="text"] {
    width: 15rem;
}

#searchtop .form-city-container {
    width: 176px;
}

#searchtop .form-city-container input {
    width: 100%;
    max-width: none;
    vertical-align: middle;
    height: 41px;
}

#search .form-city-container input {
    height: 3.8rem;
    min-width: 0;
}

article.search-article {
    position: relative;
    transition: all 0.2s;
    background-color: white;
}

article.search-article:hover {
    box-shadow: 0 1px 5px 1px rgba(0, 0, 0, 0.3);
}

span.edit-bar {
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    z-index: 2;
    position: absolute;
    top: 0.5rem;
    left: 1rem;
    color: rgba(255, 246, 209, 1);
}

span.edit-bar.alt {
    color: rgba(14, 28, 42, 1);
}

.btnh {
    /*background: rgba(0, 0, 0, 0.3); */
    background: rgba(14, 28, 42, 1);
    border-radius: 100%;
    padding: 1rem;
    text-align: center;
    color: rgba(255, 246, 209, 1);
    /*border: 0.2rem solid rgba(255, 246, 209, 1);*/
    border: 0;
    min-width: 52px;
    min-height: 52px;
    max-height: 52px;
    line-height: 100%;
    transition: all 0.1s linear;
    box-shadow: 0 0 2rem .1rem rgba(0, 0, 0, 0.7);
}

button img, button svg {
    width: 2rem;
    height: 3rem;
}

.btnh:hover, .btnh:focus {
    /*   background: rgba(14, 28, 42, 1);*/
    box-shadow: 0 0 2rem 0.1rem rgba(255, 246, 209, 0.8);
}

button svg path {
    transition: all 0.1s ease-in-out;
}

.btnh svg path {
    fill: rgba(255, 246, 209, 1);
}

.bar-addr span {
    display: block;
}

.photo-container {
    margin: auto;
    text-align: center;
}

.barcontainer .open {
    position: relative;
    line-height: 16px;
    text-align: left;
}

.barcontainer .hero-overlay {
    height: 100%;
    background: linear-gradient(to bottom, transparent 0, rgba(0, 0, 0, 0.8) 75%);
}

.bar-img {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 25rem;
}

.bar-sub {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    color: white;
    padding: 1rem;
}

.info-container {
    display: none;
}

.barcontainer h3, .beer-container h3 {
    margin: 0 0 1rem;
    line-height: 16px;
}

.beer-container {
    position: absolute;
    top: 0;
    left: 0;
    background: white;
    width: 100.1%;
    height: 100%;
    max-height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    transition: all 0.2s ease-in-out;
    display: none;
}

.beersdisplayed {
    display: block;
}

.beerlist-btn, .nav-button {
    position: absolute;
    top: 17rem;
    z-index: 2;
    right: 1rem;
}

.beerlist-btn {
    top: 1rem;
}

.bar-addr {
    display: block;
}

.country-name {
    display: none;
}

.street-address {
    display: block;
}

.bar-address p {
    margin: 0 0 0.5rem;
    line-height: 2rem;
}

.bar-skills ul {
    list-style: none;
    padding: 0;
}

i.open-circle {
    color: seagreen;
}

i.closed-circle {
    color: indianred;
}

.gm-style-iw + div {
    display: none;
}

.gm-style-iw .iw-title {
    font-size: 1.6rem;
    font-family: "Museo", sans-serif;
    font-weight: bold;
    margin-left: 2rem;
    text-align: center;
    color: #0e1c2a;
}

/*
 * FORM
 */

.addform, .editform, .addbeerform, .addbreweryform, .about-section, .addphotoform, .addreviewform {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    height: 100%;
    -webkit-transform: translateX(-110%);
    -moz-transform: translateX(-110%);
    -ms-transform: translateX(-110%);
    -o-transform: translateX(-110%);
    transform: translateX(-110%);
    background: rgba(0, 0, 0, 0.6);
    transition: 200ms linear all;
    overflow-y: scroll;
}

.addbeerform {
    z-index: 100;
}

.addbreweryform {
    z-index: 101;
}

.about-section {
    text-align: center;
}

.about-section a {
    color: white;
}

.about-section a {
    color: white;
}

.about {
    cursor: pointer;
}

.next {
    text-align: justify;
}

.addbar-container .next h3 {
    font-weight: 700;
    text-align: left;
}

.addbar-container .next a {
    font-weight: 700;
}

.about-content p {
    text-align: justify;
}

.about-content h3 {
    text-transform: uppercase;
    font-family: Oswald, sans-serif;
}

.editform, .addphoto {
    z-index: 99;
}

.addformdisplayed {
    display: block;
    -webkit-transform: translateX(0%);
    -moz-transform: translateX(0%);
    -ms-transform: translateX(0%);
    -o-transform: translateX(0%);
    transform: translateX(0%);
}

.addbarlink {
    cursor: pointer;
}

.addbar-container {
    margin: 0 auto;
    min-width: 100vw;
    max-width: 100vw;
    background: rgba(255, 246, 209, 0.3);
}

.form-group {
    width: 100%;
    padding: 5px 0;
}

.addbar-container h2 {
    text-align: center;
    margin-bottom: 1rem;
}

.addbar-container h3 {
    font-weight: normal;
}

.addbar-container .closeform, .close-edit-form, .close-beers, .close-about,
.close-beer-form, .close-addphoto-form, .close-addreview-form {
    position: relative;
    float: right;
    cursor: pointer;
}

.close-beers {
    position: absolute;
    top: 0.5rem;
    right: 1rem;
    display: none;
    z-index: 10;
}

.addbar-form {
    opacity: 1;
    margin: auto;
    padding-top: 8rem;
    padding-bottom: 7rem;
    min-height: 100vh;
    height: auto;
    box-shadow: 0 3px 4px rgba(0, 0, 0, .25);
    background: white;
}

.addbarField input[type="text"] {
    width: 35rem;
    min-width: 15rem;
}

.addbarField p {
    font-size: 1.2rem;
}

.displayResult {
    margin: auto;
}

.displayResult ul {
    list-style: none;
    padding: 0;
}

.displayResult span.changebar {
    float: right;
    cursor: pointer;
}

.displayResult h3 {
    display: inline-block;
    margin: 0;
}

.addbar-container h4 {
    margin: 1rem 0;
}

.addbar-beers {
    display: none;
}

.addbar-perks {
    display: none;
}

.addbar-perks.show {
    display: flex;
}

.editbar-perks {
    display: flex;
}

.perks-label span {
    display: block;
    font-size: 10px;
    transition: all 0.2s ease-in-out;
}

.perks-label img {
    width: 8rem;
    opacity: 0.5;
    transition: all 0.2s ease-in-out;
}

.perks-label {
    text-align: center;
}

.block {
    display: block;
}

.block img {
    height: 2.2rem;
}

.perks-label input[type="checkbox"] {
    margin: auto;
    display: block;
}

:checked + label img, :checked + label, :checked + label span {
    color: #0e1c2a;
    opacity: 1;
}

:not(:checked) + label img, :not(:checked ) + label, :not(:checked) + label span {
    color: #0e1c2a;
    opacity: 0.6;
}

label[for=range], label[for=range] span {
    opacity: 1;
}

.perks-label input[type="checkbox"] {
    display: none;
}

/* INPUT */

.nav-button i {
    padding: 0 5px;
}

input[type="text"], input[type="email"],
input[type=number] {
    min-width: 30rem;
}

textarea {
    min-width: 50rem;
}

input[type="text"], input[type="submit"],
input[type="password"],
input[type="email"], select, button[type="submit"],
input[type="search"], .select2-selection.select2-selection--multiple,
button.btn-alt, textarea,
input[type=number] {
    font-size: 1.6rem;
    appearance: none;
    box-shadow: none;
    padding: 1rem;
    border: solid 1px #000;
    transition: box-shadow 0.2s, border 0.2s;
    outline: none;
    border-radius: 2px;
}

#search input[type="text"] {
    border: 0;
    border-radius: 0;
}

input[type="text"]:focus, input[type="password"]:focus,
input[type="text"].focus, input[type="search"]:focus {
    border: solid 1px #FFF6D1;
}

#search input[type="text"]:focus {
    border: 0;
    box-shadow: none;
}

.select2-selection.select2-selection--multiple {
    max-width: 55rem !important;
    min-width: 35rem !important;
    width: 55rem;
    margin: 0;
}

.select2-selection.select2-selection--single {
    width: 30rem;
    margin: 0;
    color: #000;
    height: 41px;
    border-radius: 2px;
    border: 1px solid rgba(14, 28, 42, 1);
}

/* select2 inputs */

.select2-container--default .select2-selection--multiple .select2-selection__choice {
    background-color: rgba(14, 28, 42, 1);
    border: 1px solid #FFF6D1;
    color: white;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
    color: #FFF6D1;
}

.select2-container {
    width: auto !important;
}

.select2-container .select2-search--inline,
.select2-container--default .select2-search--inline .select2-search__field {
    width: 100% !important;
}

.select2-container--default .select2-results__option[aria-selected=true] {
    background-color: #FFF6D1;
    color: rgba(14, 28, 42, 1);
}

.select2-container--default .select2-selection--single {
    background-color: white;
    color: #000;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
    line-height: 4rem;
    text-align: left;
}

.add-beer input[type=text],
.add-beer input[type=number] {
    width: 25rem;
}

span.name {
    font-weight: 700;
}

span.style,
span.brewery {
    font-size: 12px;
    display: block;
}

span.style {
    font-style: italic;
}

#indexform > .select2 {
    width: calc(50% - 9rem) !important;
    position: relative;
    height: 40px;
    top: -2px;
    vertical-align: middle;
    font-size: 1.6rem;
    outline: 0;
}

#select2-beerselect-container {
    padding: 0;
    height: 100%;
    vertical-align: middle;
    line-height: 40px;
    text-align: left;
    padding-left: 1rem;
}

#indexform .select2-selection--single,
.select2-selection--single {
    border: 0;
    height: 41px;
    width: 100%;
    border-radius: 0;
    outline: none;
}

#indexform .select2-selection__arrow {
    display: none;
}

input[type="submit"], button[type="submit"],
.iw-link a {
    width: auto;
    min-width: 5rem;
    border: 2px solid rgba(14, 28, 42, 1);
    padding: 1rem;
    cursor: pointer;
    transition: all 0.1s ease-in-out;
    background-color: white;
    color: rgba(14, 28, 42, 1);
}

.iw-link a {
    margin-left: 2rem;
    padding: 0.5rem 1rem;
}

.addform input[type="submit"], .addform button[type="submit"], .editform input[type="submit"], .editform button[type="submit"] {
    margin: 0;
}

input[type="submit"]:hover, button[type="submit"]:hover, .iw-link a:hover {
    background-color: #fff6d1;
}

button[disabled], html input[disabled] {
    background: whitesmoke;
}

input[type=range] {
    -webkit-appearance: none;
    width: 100%;
    margin: 8.75px 0;
}

input[type=range]:focus {
    outline: none;
}

input[type=range]::-webkit-slider-runnable-track {
    width: 100%;
    height: 5.5px;
    cursor: pointer;
    background: #0e1c2a;
    border-radius: 24.1px;
    border: 0 solid #0e1c2a;
}

input[type=range]::-webkit-slider-thumb {
    border: 1px solid #0e1c2a;
    height: 23px;
    width: 22px;
    border-radius: 50px;
    background: #0e1c2a;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -8.75px;
}

input[type=range]:focus::-webkit-slider-runnable-track {
    background: #0e1c2a;
}

input[type=range]::-moz-range-track {
    width: 100%;
    height: 5.5px;
    cursor: pointer;
    background: #0e1c2a;
    border-radius: 24.1px;
    border: 0 solid #0e1c2a;
}

input[type=range]::-moz-range-thumb {
    border: 1px solid #0e1c2a;
    height: 23px;
    width: 22px;
    border-radius: 50px;
    background: #0e1c2a;
    cursor: pointer;
}

input[type=range]::-ms-track {
    width: 100%;
    height: 5.5px;
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    color: transparent;
}

input[type=range]::-ms-fill-lower {
    background: #0e1c2a;
    border: 0px solid #0e1c2a;
    border-radius: 48.2px;
    box-shadow: 1px 1px 3.4px #0e1c2a, 0px 0px 1px #14293d;
}

input[type=range]::-ms-fill-upper {
    background: #0e1c2a;
    border: 0px solid #0e1c2a;
    border-radius: 48.2px;
    box-shadow: 1px 1px 3.4px #0e1c2a, 0px 0px 1px #14293d;
}

input[type=range]::-ms-thumb {
    border: 1px solid #0e1c2a;
    height: 23px;
    width: 22px;
    border-radius: 50px;
    background: #0e1c2a;
    cursor: pointer;
    height: 5.5px;
}

input[type=range]:focus::-ms-fill-lower {
    background: #0e1c2a;
}

input[type=range]:focus::-ms-fill-upper {
    background: #0e1c2a;
}

#search input[type="checkbox"], #search input[type="radio"] {
    margin-left: 2rem;
}

.range-container label {
    font-size: 1.2rem;
    text-align: center;
}

/* google autocomplete */

.pac-container {
    font-family: "Raleway", Arial, sans-serif;
    border: 1px solid #0e1c2a;
    border-radius: 4px;
    border-top: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    width: 100%;
    margin: 0;
    padding: 0;
}

.pac-item {
    color: #0e1c2a;
    background: white;
    padding: 6px;
}

.pac-item:hover {
    color: #FFF6D1;
    background: #0e1c2a;
}

.pac-item:hover .pac-item-query {
    color: #FFF6D1;
}

.pac-icon {
    display: none;
}

.select2-search__field {
    border-radius: 0;
    border: solid 1px #000;
}

.select2-dropdown {
    border: 1px solid #0e1c2a;
}

#search .select2-dropdown.select2-dropdown--below {
    top: 0.9rem;
    left: -1rem;
    border: 0;
}

body[data-locale=en] #indexform > .select2 {
    width: calc(50% - 5rem) !important;
}

.range-container {
    display: inline-block;
    vertical-align: middle;
    width: calc(50% - 20rem);
    height: 4rem;
}

.range-container label {
    display: block;
}

#searchtop .range-container {
    width: 176px;
}

.form-city-container {
    display: none;
    width: calc(50% - 20rem);
}

.checkbox-container {
    display: inline-block;
    text-align: left;
    vertical-align: middle;
    margin-left: 2rem;
}

.checkbox-container input {
    margin-right: 1rem;
    display: none;
}

#search label[for=craft] img {
    height: 3rem;
}

.info-nav {
    text-align: center;
    margin: 10px 0;
}

.info-contact a {
    display: block;
}

.info-specs {
    text-align: center;
}

.info-contact {
    padding: 5px 0;
}

.info-specs {
    padding: 1rem 0;
}

.info-specs i, .info-contact i {
    padding: 0 5px;
}

footer {
    background: rgba(14, 28, 42, 1);
    position: relative;
    bottom: 0;
    left: 0;
    width: 100%;
    max-width: 100vw;
    color: white;
    text-transform: uppercase;
    font-size: 1rem;
    padding: 2rem 0;
}

footer ul {
    padding: 0;
    list-style: none;
}

footer a {
    line-height: 2rem;
    font-size: 1.4rem;
}

.mobile-map, .add-bar-icon {
    color: #FFF6D1;
    cursor: pointer;
}

.mobile-map {
    right: 0;
    left: auto;
    border-top-left-radius: 2px;
    top: 10rem;
    border-bottom-left-radius: 2px;
    background: rgba(0, 0, 0, 0.6);
    padding: 1rem 2rem;
    position: fixed;
    display: none;
}

.add-bar-icon {
    left: 5rem;
}

.mobile-map .fa, .add-bar-icon .fa {
    transition: all 0.2s linear;
}

.error-container {
    position: fixed;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    margin: auto;
    width: 100%;
    z-index: 10;
    color: white;
    text-align: center;
}

.error-msg h1 {
    color: white;
}

.wrap {
    position: relative;
    min-height: 100vh;
}

/******************
    ADD BEER
 */

.add-beer-container {
    display: none;
}

i.fa.fa-heart {
    color: indianred;
}

/******************
    LOGIN
 */

.login-container {
    display: none;
    background: #fff;
    box-shadow: 0 3px 8px rgba(0, 0, 0, .25);
    position: absolute;
    right: 8rem;
    top: 6rem;
    z-index: 12;
    line-height: normal;
    font-size: 1.2rem;
    border-radius: 2px;
}

.login-container input {
    line-height: normal;
    font-size: 1.2rem;
    width: 100%;
    min-width: 0;
}

.login-container a {
    color: #000;
}

.login-container a:hover {
    color: #0e1c2a;
}

.login-container:after {
    bottom: 100%;
    left: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: #ffffff;
    border-width: 10px;
    margin-left: -10px;
}

/********************
    LOCALE
 */

body[data-locale=fr] .login-container {
    right: 10rem;
}

/** Jquery autocomplete **/

.ui-widget.ui-widget-content {
    border: 0;
}

.ui-widget-content {
    border: 0;
    color: #0e1c2a;
}

.ui-widget {
    font-family: "Raleway", sans-serif;
    font-size: 1.6rem;
}

.ui-menu .ui-menu-item-wrapper {
    padding: 1rem;
}

.ui-state-active, .ui-widget-content .ui-state-active,
.ui-widget-header .ui-state-active, a.ui-button:active,
.ui-button:active, .ui-button.ui-state-active:hover {
    border: 0;
    background: #0e1c2a;
    color: #fff6d1;
}

.error-msg {
    color: indianred;
    font-weight: 700;
}