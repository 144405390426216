@media screen and (max-width: 960px) {
    .country-container, .bar-finder .city-container {
        width: calc(100vw / 2 - 4rem);
    }
}

@media screen and (max-width: 850px) and (min-width: 769px) {
    .sidebar article {
        width: calc(100% - 2rem - .01px) !important;
    }
}

@media screen and (max-width: 767px) {
    .sidebar article {
        position: relative;
        min-height: 25rem;
    }

    .mobile-search, .mobile-map {
        display: block;
    }

    #searchtop {
        width: 100%;
    }

    #searchtop .form-city-container {
        width: 100%;
    }

    #map {
        position: fixed !important;
        width: 100%;
        height: calc(100vh - 7.2rem);
        margin: 0;
        -webkit-transform: translateX(120%) !important;
        -moz-transform: translateX(120%) !important;
        -ms-transform: translateX(120%) !important;
        -o-transform: translateX(120%) !important;
        transform: translateX(120%) !important;
        z-index: 4;
    }

    #map.show {
        -webkit-transform: translateX(0%) !important;;
        -moz-transform: translateX(0%) !important;;
        -ms-transform: translateX(0%) !important;;
        -o-transform: translateX(0%) !important;;
        transform: translateX(0%) !important;;
    }

    .addbar-container h2,
    .addbar-container h3 {
        margin: 0;
    }

    .addbar-container h3 {
        font-size: 1.6rem;
    }

    .sidebar {
        width: 100%;
        left: 0;
    }

    .caption h2 {
        font-size: 2rem;
    }

    .caption.small h3 {
        font-size: 1.5rem;
        padding: 1rem;
    }

    input[type="text"],
    input[type="password"],
    input[type="number"],
    textarea,
    input[type="email"] {
        width: 100%;
        min-width: 0;
    }

    #search input[type="checkbox"], #search input[type="radio"] {
        margin-left: 0.3rem;
    }

    input[type="search"], .select2-selection.select2-selection--multiple,
    .addbarField input[type="text"],
    .select2-selection.select2-selection--single {
        width: 100% !important;
        min-width: 0 !important;
    }

    #search .select2-dropdown.select2-dropdown--below {
        width: 100% !important;
    }

    #select2-beerselect-container {
        border-bottom: 1px solid rgba(0, 0, 0, 0.5);
        padding: 0;
    }

    #search {
        position: relative;
        width: 100%;
    }

    #search .divider:first-of-type {
        display: none;
    }

    #search .divider {
        margin: 0;
    }

    .form-city-container, .range-container {
        width: calc((100vw - 4rem) - 7.5rem);
        margin: 1rem 0;
    }

    .checkbox-container {
        width: 7rem;
        margin-left: 0;
        text-align: right;
    }

    #search button[type=submit] {
        position: relative;
        height: inherit;
        top: inherit;
        width: 100%;
    }

    .form-city-container input {
        margin: 0;
        width: 100%;
    }

    #indexform > .select2, #indexform .select2-selection--single,
    body[data-locale=en] #indexform > .select2 {
        width: 100% !important;
    }

    .addbar-perks.show {
        display: block;
    }

    .perks-label {
        display: inline-block;
        width: calc(100% / 4 - 0.1px - 0.5rem);
    }

    .perks-label label {
        display: inline-block;
    }

    #sendform {
        width: 50%;
        margin: auto;
        display: block;
    }

    #indexform label {
        margin-bottom: 2rem;
    }

    .editbar-perks {
        display: block;
    }

    /* search */
    #searchtop {
        display: none;
    }

    .bar-skills ul {
        display: block;
        width: 100%;
        padding: 0;
        margin-bottom: 1rem;
    }

    .beerslist ul {
        display: block;
        border: 0;
        margin: 0;
    }

    span.opened, span.closed {
        width: auto;
        right: 2rem;
        top: 0;
    }

    .message_popin {
        max-width: 30rem;
    }

    .select2, .select2-container {
        width: 100% !important;
    }

    .add-beer input[type=text],
    .add-beer input[type=number] {
        width: 100%;
    }

    .bars-list-container:before {
        display: none;
    }

    .city-container {
        width: calc(100vw / 1.5);
    }

    .bars-list-container {
        padding: 2rem;
    }

    #home-bars-container .map-container {
        height: calc(100vh - 5rem);
    }

    .home-select-country {
        margin-top: 0;
    }

    .toggle-menu {
        display: block;
    }

    header .divider {
        display: none;
    }

    .caption {
        padding: 2rem 0 0;
    }

    .caption h1 {
        font-size: 1.4rem;
    }

    /** Bar finder **/
    .bar-finder .caption h1 {
        font-size: 3rem;
    }

    .bar-finder-control a {
        font-size: 2rem;
        margin-right: 1rem;
    }

    .actions a.btn {
        padding: 1rem 1.5rem;
    }

    .perks-label span {
        font-size: 0.8rem;
    }

    .bar-finder-results .perks-label span {
        font-size: 14px;
    }

    .perks-label img {
        width: 5rem;
    }

    .bar-finder-filters .perks-label span {
        font-size: 10px;
        text-transform: none;
    }

    .city-image-container {
        height: 15rem;
    }

    .city-image-container .overlay {
        background-image: linear-gradient(rgba(0, 0, 0, .8) 0, transparent 80%);
    }

    .index-search {
        min-height: 40rem;
    }
}

@media screen and (max-width: 660px) {
    .hidden-xs {
        display: none;
    }

    .home-select-country select {
        margin-right: 0;
    }

    .home-select-country label {
        margin-left: 0;
    }
}