.top-line-yellow {
    border-top: 4px solid #fadb32;
}

.homepage {
    background-color: #0e1c2a;
}

.v-centered {
    top: 50%;
    position: relative;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 2;
}

#home-cities-container {
    width: auto;
    overflow-x: auto;
    white-space: nowrap;
    min-height: 34vh;
    position: relative;
    font-size: 0;
    background-color: #0e1c2a;
    border-bottom: 1px solid rgba(14, 28, 42, 1);
}

#home-cities-container::-webkit-scrollbar {
    background: #fadb32;
    height: 1rem;
}

#home-cities-container::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.5);
    border-radius: 1ex;
    -webkit-border-radius: 1ex;
}

#home-cities-container .no-results a {
    color: white;
    text-align: center;
    font-size: 1.6rem;
    margin-top: 2rem;
}

.city-container,
.country-container {
    height: 33.33vh;
    width: calc(100vw / 4);
    border: 0;
    border-right: 1px solid white;
    text-align: center;
    cursor: pointer;
    display: inline-block;
    position: relative;
}

.city-container .content,
.country-container .content {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
}

.city-container .content:hover,
.city-container.active .content,
.country-container.active .content,
.country-container .content:hover {
    background-color: rgba(112, 112, 57, 0.8);
}

.city-container .content:hover h3,
.city-container.active .content h3,
.country-container .content:hover h3,
.country-container.active .content h3 {
    color: #fadb32;
}

.city-container .title,
.country-container .title {
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
}

.city-container h3,
.city-container h4,
.country-container h3,
.country-container h4 {
    text-transform: uppercase;
    font-family: 'Oswald', sans-serif;
    font-weight: 900;
    margin: 0;
    color: white;
}

.city-container h4,
.country-container h4 {
    font-weight: 300;
}

.city-container .content,
.city-container .content h3,
.country-container .content,
.country-container .content h4 {
    -webkit-transition: all 0.2s cubic-bezier(.16, .36, .78, .9);
    -moz-transition: all 0.2s cubic-bezier(.16, .36, .78, .9);
    -ms-transition: all 0.2s cubic-bezier(.16, .36, .78, .9);
    -o-transition: all 0.2s cubic-bezier(.16, .36, .78, .9);
    transition: all 0.2s cubic-bezier(.16, .36, .78, .9);
}

.home-select-country {
    position: absolute;
    bottom: 0;
    width: 100%;
}

.home-select-country .content {
    position: relative;
    display: inline-block;
    background: white;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    box-shadow: 0 -4px 10px 0 rgba(0, 0, 0, .2);
}

.quick-overview-help {
    position: absolute;
    top: 1rem;
    width: 1.5rem;
    right: 1rem;
}

.home-select-country label,
.home-select-country select {
    font-family: 'Raleway', sans-serif;
    opacity: 1;
}

.home-select-country label {
    cursor: default;
}

.home-select-country select {
    background-color: transparent;
    border: 0;
    padding: 0;
    font-weight: 900;
    cursor: pointer;
    margin-right: 3rem;
}

.home-select-country p {
    color: white;
}

.home-select-country a {
    font-weight: 900;
    color: #fadb32;
}

#home-bars-container .map-container,
.bar-finder-results .map-container {
    height: 90%;
}

.bar-finder .caption h1 {
    font-size: 4rem;
    font-family: "Museo", Arial, sans-serif;
    text-align: center;
}

.bar-finder-control a {
    font-family: 'Oswald', sans-serif;
    color: white;
    text-transform: uppercase;
}

.bar-finder h1 {
    text-shadow: -3px 4px 1px rgba(14, 28, 42, 0.67);
}

.bar-finder-control a {
    font-size: 3rem;
}

.bar-finder-control a.active {
    text-shadow: -3px 2px 1px rgba(14, 28, 42, 0.67);
    color: #fadb32;
}

.bar-finder-countries,
.bar-finder-cities {
    width: auto;
    overflow-x: auto;
    white-space: nowrap;
    font-size: 0;
}

.country-container,
.bar-finder .city-container {
    height: 25vh;
    width: calc(100vw / 5 - 4rem);
    border: 0;
    border-radius: 4px;
}

.country-container .content,
.bar-finder .city-container .content {
    border-radius: 4px;
}

.bar-finder-cities,
.bar-finder-filters {
    display: none;
}

.bar-finder-content {
    position: relative;
}

.bar-finder-results {
    background-color: #0e1c2a;
    height: 25vh;
}

.bar-finder-results.full-display {
    min-height: 100vh;
    height: auto;
}

.bar-finder-filters .perks-label span {
    color: white;
    font-family: 'Oswald', sans-serif;
    text-transform: uppercase;
    font-size: 16px;
}

.bar-finder-results .bars-list-container {
    padding-top: 3rem;
}

.bar-finder-results .city-info {
    margin-top: 1rem;
}

.iw-taps {
    color: #000;
}

.city-image-container {
    position: relative;
}

.city-image-container .overlay {
    background-image: linear-gradient(rgba(0, 0, 0, .8) 0, transparent 40%);
    height: 100%;
}

.city-image-container .background {
    height: 100%;
    width: 100%;
}

/** Hambuger **/

.toggle-menu {
    background: transparent;
    position: absolute;
    top: 2.3rem;
    right: 2rem;
    height: 3rem;
    width: 3rem;
    cursor: pointer;
    display: none;
}

.toggle-menu:before, .toggle-menu:after {
    background: #fff;
    backface-visibility: hidden;
    content: "";
    height: 2px;
    left: 0;
    transition: 0.75s;
    width: 3rem;
}

.toggle-menu:before {
    box-shadow: #fff 0 12px 0 0;
    position: absolute;
    top: 0;
}

.toggle-menu:after {
    position: absolute;
    top: calc(100% - 6px);
}

.toggle-menu.active:before {
    box-shadow: transparent 0 0 0 0;
    top: 50%;
    -webkit-transform: rotate(225deg);
    -moz-transform: rotate(225deg);
    -ms-transform: rotate(225deg);
    -o-transform: rotate(225deg);
    transform: rotate(225deg);
}

.toggle-menu.active:after {
    top: 50%;
    -webkit-transform: rotate(315deg);
    -moz-transform: rotate(315deg);
    -ms-transform: rotate(315deg);
    -o-transform: rotate(315deg);
    transform: rotate(315deg);
}

.menu-container {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 71px;
    left: 0;
    z-index: 1000;
    background: rgba(0, 0, 0, 0.9);
    display: none;
}

ul.mobile-menu {
    list-style: none;
    padding-left: 2rem;
}

ul.mobile-menu li {
    margin-bottom: 1rem;
}

ul.mobile-menu li a {
    color: white;
    font-size: 2rem;
    text-transform: uppercase;
    font-family: 'Oswald', sans-serif;
}

ul.mobile-menu li a {
    color: white;
}

.actions a.btn {
    padding: 1rem 2rem;
    border-radius: 4px;
    font-family: 'Oswald', sans-serif;
    text-transform: uppercase;
    color: white;
    background: #0e1c2a;
}

.actions a.btn:hover {
    opacity: 0.8;
}

#home-last-additions {
    background: white;
}

#home-last-additions h2 {
    text-align: center;
    font-family: "Oswald", sans-serif;
    text-transform: uppercase;
}

#home-last-additions h3 {
    margin: 0;
}

#home-last-additions p {
    margin: 0;
}

.info-craft {
    color: #fadb32;
}

#bar-finder-filter {
    background: transparent;
    border: 0;
    border-bottom: 2px solid white;
    color: white;
    opacity: 1;
    font-weight: 600;
}

.bar-finder ::-webkit-input-placeholder,
.bar-finder ::-moz-placeholder,
#bar-finder-filter {
    color: white;
    opacity: 1;
    font-weight: 600;
    font-style: italic;
}

.bar-map-container {
    display: none;
    position: fixed;
    z-index: 100;
    width: 100%;
    top: 0;
    left: 0;
    height: 100%;
    background: rgba(255, 255, 255, 0.5);
    text-align: center;
}

.bar-map-container .map {
    position: relative;
    margin: auto;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 96vw;
    max-width: 80rem;
    height: 60vh;
    max-height: 50rem;
    border-radius: 4px;
    box-shadow: 0 3px 8px rgba(0, 0, 0, .25);
}

.bar-map-container .map #bar_map {
    height: 100%;
    width: 100%;
}

.bar-map-container .map .close-map {
    position: absolute;
    right: -1rem;
    top: -1rem;
    z-index: 101;
    padding: 0.5rem 1rem;
    border-radius: 100%;
    background: #0e1c2a;
    color: white;
    cursor: pointer;
}

#mapImage {
    cursor: pointer;
}

.slider-arrow-container {
    position: absolute;
    top: 66.5vh;
    right: 0;
    height: 33.3vh;
    width: 5rem;
    opacity: 0.6;
    background: linear-gradient(to left, rgba(0, 0, 0, .8) 0, transparent 95%);
    cursor: pointer;
    text-align: center;
    display: none;
    z-index: 1;
    -webkit-transition: all 0.25s ease-in-out;
    -moz-transition: all 0.25s ease-in-out;
    -ms-transition: all 0.25s ease-in-out;
    -o-transition: all 0.25s ease-in-out;
    transition: all 0.25s ease-in-out;
}

.slider-arrow-container:hover {
    background: linear-gradient(to left, rgba(0, 0, 0, 0.8) 0, transparent 99%);
    opacity: 0.8;
}

.slider-arrow-container .right-arrow {
    top: 50%;
    position: relative;
    color: white;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
}

main.bg-blue {
    background-color: #0e1c2a;
}

.error-msg {
    text-align: center;
}

ul.breadcrumb {
    padding: 0;
}

ul.breadcrumb li {
    display: inline-block;
}

ul.breadcrumb li a {
    font-size: 12px;
    display: inline-block;
    max-width: 300px;
    vertical-align: middle;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}