body.full {
    overflow-y: auto;
}

body.full footer {
    position: relative;
}

.wrap.bar-page {
    height: auto;
    min-height: calc(100vh - 10rem);
}

.bar-info-container h1 {
    font-size: 4rem;
    font-weight: 700;
    margin: 0;
}

.bar-info-container h2 {
    margin: 1rem 0;
}

.bar-info-container h3, .bar-info-container p {
    margin: 0;
}

.hero-overlay {
    background-image: linear-gradient(to bottom, transparent 0, rgba(0, 0, 0, .75) 85%);
    padding: 12rem 0 1rem;
    color: white;
}

.hero-overlay a {
    color: white;
}

.hero-overlay a:hover,
.hero-overlay a:focus {
    color: #fff6d1;
}

.bar-nav {
    background: #0e1c2a;
}

.bar-nav .navigate img {
    height: 2rem;
}

.bar-nav ul {
    list-style: none;
}

.bar-nav ul li {
    display: inline-block;
    font-weight: 700;
}

.bar-nav ul li a {
    padding: 2rem;
    display: block;
    cursor: pointer;
    font-family: 'Oswald', sans-serif;
    color: white;
    text-transform: uppercase;
}

.bar-nav ul li:not(.active):hover {
    background-color: rgb(2, 4, 6);
}

.bar-nav ul li:not(.active):hover a {
    color: white;
}

.bar-nav ul li.active {
    background: white;
}

.bar-nav ul li.active a {
    color: #0e1c2a;
}

.tab-content {
    display: none;
}

a.edit-bar {
    color: #fff6d1;
}

button.favorite {
    background-color: transparent;
    border: 0;
    color: #fff6d1;
    position: relative;
}

button.favorite i {
    margin-right: 1rem;
}

.activity a {
    color: #000;
}

.activity a:hover,
.activity a:focus {
    color: #0e1c2a;
}

.score {
    font-size: 3rem;
}

button.favorite .help {
    position: absolute;
    top: 1.5rem;
    color: white;
    opacity: 0;
    -webkit-transform: translateX(-1rem);
    -moz-transform: translateX(-1rem);
    -ms-transform: translateX(-1rem);
    -o-transform: translateX(-1rem);
    transform: translateX(-1rem);
    transition: all 0.3s cubic-bezier(.36, .64, .61, .9);
}

button.favorite:hover .help {
    opacity: 1;
    -webkit-transform: translateX(3rem);
    -moz-transform: translateX(3rem);
    -ms-transform: translateX(3rem);
    -o-transform: translateX(3rem);
    transform: translateX(3rem);
}

.social-links {
    position: absolute;
    right: 0;
    bottom: 0;
}

.social-links i.fa {
    font-size: 1.5em;
}

.relative {
    position: relative;
}

h3 a:hover, h3 a:focus {
    color: #0e1c2a;
    opacity: 0.8;
}

.img-container {
    position: relative;
}

.bar-img-overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.45);
    color: white;
    z-index: 2;
    width: 100%;
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    -ms-transition: all 0.2s;
    -o-transition: all 0.2s;
    transition: all 0.2s;
}

.bar-img-overlay:hover {
    background-color: rgba(0, 0, 0, 0.65);
}

.bar-img-overlay h3 {
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
}

/*********************
  MAGNIFIC POPUP
 */

/* overlay at start */
.mfp-fade.mfp-bg {
    opacity: 0;

    -webkit-transition: all 0.15s ease-out;
    -moz-transition: all 0.15s ease-out;
    transition: all 0.15s ease-out;
}

/* overlay animate in */
.mfp-fade.mfp-bg.mfp-ready {
    opacity: 0.8;
}

/* overlay animate out */
.mfp-fade.mfp-bg.mfp-removing {
    opacity: 0;
}

/* content at start */
.mfp-fade.mfp-wrap .mfp-content {
    opacity: 0;
    -webkit-transition: all 0.15s ease-out;
    -moz-transition: all 0.15s ease-out;
    transition: all 0.15s ease-out;
}

/* content animate it */
.mfp-fade.mfp-wrap.mfp-ready .mfp-content {
    opacity: 1;
}

/* content animate out */
.mfp-fade.mfp-wrap.mfp-removing .mfp-content {
    opacity: 0;
}

.mfp-bottom-bar {
    margin: 0;
    padding: 1rem;
    top: inherit;
    bottom: 4rem;
    background-color: rgba(0, 0, 0, 0.6);
}

.mfp-bottom-bar a {
    color: white;
}

/**** BEER ****/

.bar-info-container.beer-info-container .hero-overlay {
    padding: 0;
}

.bar-info-container.beer-info-container h2 {
    margin: 0;
}

@media screen and (max-width: 768px) {
    .bar-nav ul li a {
        padding: 1rem;
    }
}

/******** BARS LIST *****/

.bars-list-container {
    padding-top: 9rem;
    background-color: #0e1c2a;
    color: white;
    position: relative;
}

.bars-list-container:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: -15rem;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 0 100vh 15rem;
    border-color: transparent transparent #0e1c2a transparent;
}

.bars-list-container a,
.bars-list-container h2 {
    color: white;
}

.bars-list-container .map-container {
    display: none;
}

#bars_list_map {
    width: 100%;
    height: 100%;
}

.city-info {
    margin-top: 7rem;
    position: absolute;
    top: 0;
    left: 0;
}

.city-info h2 {
    font-family: "Oswald", sans-serif;
    text-transform: uppercase;
    color: white;
    font-size: 3rem;
    font-weight: 400;
}

.city-info h2 span {
    font-weight: 300;
}

.bars-list-container .display-control a,
.bars-list-container .list-container .info {
    font-family: "Oswald", sans-serif;
    text-transform: uppercase;
    font-weight: 300;
}

.bars-list-container .display-control a {
    font-size: 2rem;
}

.bars-list-container .display-control a.active {
    font-weight: 700;
}

.bars-list-container .list-container {
    overflow-y: auto;
    max-height: 75vh;
}

.bars-list-container .list-container h3 {
    font-size: 2.2rem;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid #fadb32;
    margin-bottom: 0.5rem;
}

.bar-inline .bar-favorite,
.bar-inline .beer-favorite {
    opacity: 0;
}

.bar-inline:hover .bar-favorite,
.bar-inline:hover .beer-favorite {
    opacity: 1;
}

#beers hr {
    max-width: none;
}

/** Reviews **/

.truncated-text {
    display: none;
}

.br-theme-fontawesome-stars .br-widget a {
    float: none;
    display: inline-block;
}

.br-theme-fontawesome-stars-o .br-widget a.br-selected:after,
.br-theme-fontawesome-stars-o .br-widget a.br-active:after {
    color: #fadb32;
}

/** Photo **/

#wimb_homebundle_photo_filePath {
    display: none;
}

.file-label {
    opacity: 1;
    font-weight: 700;
}

.fancybox-thumbs {
    background: transparent;
}